import React from 'react'
import styles from './Virez.module.scss'


interface IVirez {
    color?: string,
    height: number,
    padding: number,
    reverse?: boolean,
}

export const Virez: React.FC<IVirez> = ({ color, height, padding, reverse }) => {
    const radius = (height + padding) / 2;

    return (
        <div className={`${styles.Virez} ${color ? styles[color] : ''} ${reverse ? styles.Reverse : ""}`} style={{
            width: `calc(100% + ${padding}px)`,
            height: height + padding,
        }}>
            <div className={styles.Main} style={{
                borderTopLeftRadius: !reverse ? radius : "0px",
                borderTopRightRadius: reverse ? radius : "0px",
            }} >
                <div className={styles.Top} style={{
                    height: radius,
                    width: radius,
                }} />
                <div className={styles.TopRadius} style={{
                    height: radius,
                    width: radius,
                    borderBottomRightRadius: !reverse ? radius : "0px",
                    borderBottomLeftRadius: reverse ? radius : "0px"
                }} />

                <div className={styles.Left} style={{
                    height: radius,
                    width: radius,
                }} />
                <div className={styles.LeftRadius} style={{
                    height: radius,
                    width: radius,
                    borderBottomRightRadius: !reverse ? radius : "0px",
                    borderBottomLeftRadius: reverse ? radius : "0px"
                }} />
            </div>
        </div>
    )
}