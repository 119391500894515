import React, { useEffect, useRef, useState } from 'react'
import styles from './InfrastructureCard.module.scss'
import { IInfrastructure } from '../../Types/Models/types'
import { Virez } from '../Virez/Virez.tsx';

interface IInfrastructureCardProps {
    infrastructure: IInfrastructure;
    icon: string;
    index: number
}

const InfrastructureCard: React.FC<IInfrastructureCardProps> = ({ infrastructure, icon, index }) => {
    const [needExpand, setNeedExpand] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const descr = useRef<HTMLSpanElement>(null)
    useEffect(() => {
        function isTextClamped(element) {
            return element.scrollHeight > element.offsetHeight;
        }
        setNeedExpand(isTextClamped(descr.current))
    }, [])

    const toggleExpanded = () => {
        setExpanded(!expanded)
    }

    return (
        <div className={styles.Infra} style={{
            animationDelay: (0.2 * index) + 0.2 + "s",
        }}>
            <div className={styles.Content}>
                <img src={"/images/" + icon + "_black.svg"} />
                <span className={`${styles.Name} ${styles.Middle} ${styles.Bold}`}>{infrastructure.Name}</span>
                <div className={styles.DescrWrapper}>
                    <span ref={descr} className={`${styles.Descr} ${expanded ? styles.Expdanded : ""} ${needExpand ? styles.NeedExpand : ""}`}>{infrastructure.Descr}
                    </span>
                    {needExpand && <button onClick={toggleExpanded} className={`${styles.Expand} ${expanded ? styles.Expanded : ""}`}></button>}
                </div>
            </div>
            <div className={styles.ImageWrappes}>
                <img src={infrastructure.Image} />
            </div>
            <a target="_blank" rel="noopener noreferrer" className={`${styles.Map} ${styles.Regular}`} href={infrastructure.Map}>
                <Virez height={60} padding={20} />
                <div className={styles.ContentButton}>
                    Посмотреть на карте
                </div>
            </a>
        </div>
    )
}

export default InfrastructureCard