import React, { forwardRef, useEffect, useRef, useState } from 'react'
import styles from './Book.module.scss'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './calendar.css'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import { IApart, IService } from '../../Types/Models/types';
import Loading from '../Loading/Loading.tsx'
import AddServices from '../AddServices/AddServices.tsx';

interface IBookProps {
    apart?: IApart,
    setIsOpenBook?: React.Dispatch<React.SetStateAction<boolean>>,
    wantedService?: IService,
    noHeight?: boolean,
}

const props = { placeholder: 'Please Select...' };
const Book: React.FC<IBookProps> = ({ apart, setIsOpenBook, wantedService, noHeight }) => {
    const [dateRange, setDateRange] = useState([undefined, undefined]);
    const [name, setName] = useState("");
    const [number, setNumber] = useState("+7")
    const [guests, setGuests] = useState("2");
    const [startDate, endDate] = dateRange;
    const [errorDate, setErrorDate] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorNumber, setErrorNumber] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loadError, setLoadError] = useState(false);
    const [pickedServices, setPickedServices] = useState<IService[]>([]);
    const [isOpenPickServices, setIsOpenPickServices] = useState(false);
    const [comment, setComment] = useState('');

    const commentAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        const commentArea = commentAreaRef.current;
        if (commentArea) {
            // Сбрасываем высоту перед пересчетом
            commentArea.style.height = "auto";

            // Устанавливаем высоту на основе содержимого
            const maxHeight = commentArea.scrollHeight - 13;
            commentArea.style.height = `${Math.min(maxHeight, 5 * 13) + 10}px`; // 20px — высота строки (примерно)
        }
    }, [comment])

    const send_telegram = async (number: string, name: string, dateIn: Date, dateOut: Date) => {
        var error = false;
        if ((!number || number === '') || !isPossiblePhoneNumber(number)) {
            setErrorNumber(true)
            error = true;
        }
        if (!name || name === '') {
            setErrorName(true)
            error = true;
        }
        if (!dateIn || !dateOut || dateIn == undefined || dateOut == undefined || dateIn == null || dateOut == null) {
            setErrorDate(true)
            error = true;
        }
        if (error) {
            return;
        }
        const dateInString = `${dateIn.getFullYear()}-${String(dateIn.getMonth() + 1).padStart(2, '0')}-${String(dateIn.getDate()).padStart(2, '0')}`;
        const dateOutString = `${dateOut.getFullYear()}-${String(dateOut.getMonth() + 1).padStart(2, '0')}-${String(dateOut.getDate()).padStart(2, '0')}`;
        try {
            setLoading(true);
            setLoadError(false);
            const response = await axios.post("https://us-central1-in-premium.cloudfunctions.net/helloWorld", {
                name: name,
                phone: number,
                dateIn: dateInString,
                dateOut: dateOutString,
                apart: apart ? apart.Name : "-",
                guests: guests,
                services: pickedServices.length > 0 ? pickedServices.map(obj => obj.title).join(", ") : "-",
                comment: comment ? comment : "-"
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log("Ответ от сервера:", response.data);
            setSuccess(true);
            setName("");
            setNumber("+7");
            setDateRange([undefined, undefined]);
            setGuests("2");
            setPickedServices([]);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoadError(true);
                setLoading(false);
            }, 200)
        } finally {
        }
    }
    useEffect(() => {
        if (!setIsOpenBook) {
            if (isOpenPickServices) {
                const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
                document.body.style.overflowY = "hidden";
                document.body.style.paddingRight = `${scrollBarWidth}px`;
            }
            else if (!isOpenPickServices) {
                document.body.style.overflowY = "";
                document.body.style.paddingRight = "";
            }
        }
    }, [isOpenPickServices])

    useEffect(() => {
        if (wantedService)
            setPickedServices([wantedService])
    }, [])

    useEffect(() => {
        if (Number(guests) < 1) {
            setGuests("0")
        }
        else if (Number(guests) > 20) {
            setGuests("20")
        }
    }, [guests])
    useEffect(() => {
        setErrorNumber(false);
    }, [number])
    useEffect(() => {
        setErrorName(false);
    }, [name])
    const ExampleCustomInput = forwardRef(
        //@ts-ignore
        ({ value, onClick, className }, ref) => (
            //@ts-ignore
            <button className={className} onClick={onClick} ref={ref}>
                {value
                    ? value : "Выберите даты"}
            </button>
        ),
    );

    const addService = (newService: IService) => {
        setPickedServices((prevItems) => {
            const exists = prevItems.some((item) => item.title === newService.title);
            if (!exists) {
                return [...prevItems, newService]; // Добавляем новый объект
            } else {
                console.log(`Объект с именем "${newService.title}" уже существует.`);
                return prevItems; // Ничего не меняем
            }
        });
    };
    const removeService = (removeService: IService) => {
        setPickedServices((prevItems) => prevItems.filter((item) => item.title !== removeService.title));
    };
    return (
        <>
            {
                isOpenPickServices &&
                <div className={`${setIsOpenBook ? styles.OpacityWrapper : ""} ${styles.WrapperPickServices}`}>
                    <AddServices addService={addService} removeService={removeService} pickedServices={pickedServices} setIsOpenPickServices={setIsOpenPickServices} />
                </div>
            }
            <div className={`${styles.Book} ${setIsOpenBook ? styles.Custom : ""} ${(isOpenPickServices && setIsOpenBook) ? styles.HideThis : ""}`}
                style={{
                    maxHeight: noHeight ? "1000000px" : "p"
                }}>
                {setIsOpenBook &&
                    <button className={styles.close} onClick={() => {
                        setIsOpenBook(false)
                    }} />
                }
                <div className={`${styles.success} ${success ? styles.visible : ""}`}>
                    <span className={`${styles.Middle} ${styles.Bold}`}>
                        Спасибо за вашу заявку на бронирование!
                    </span>
                    <span>
                        Мы получили ваше обращение и в ближайшее время свяжемся с вами
                    </span>
                    <button onClick={() => setSuccess(false)} className={`${styles.Regular} ${styles.closeSuccess}`}>
                        Закрыть
                    </button>
                </div>
                <div className={styles.order}>
                    <span className={`${styles.Middle} ${styles.Bold} ${styles.Title}`}>
                        Бронирование {(apart) ? <>
                            <br />
                            <span className={`${styles.Hide} ${styles.Small}`}>
                                "{apart.Name.replace(/_/g, ' ')}"
                            </span>
                        </>
                            : ""}
                    </span>
                    <div className={styles.form}>
                        <div className={`${styles.date} ${styles.InputField} ${errorDate ? styles.error : ""}`}>
                            <span className={styles.bookTitle}>
                                Заселение и выезд
                            </span>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    //@ts-ignore
                                    setDateRange(update);
                                }}
                                onCalendarOpen={() => setErrorDate(false)}
                                placeholderText='Выберите даты'
                                isClearable={true}
                                dateFormat="MMMM d, yyyy"
                                minDate={new Date()}
                                //@ts-ignore
                                customInput={<ExampleCustomInput className={styles.calendarButton} />}
                            />
                        </div>
                        <div className={`${styles.guest} ${styles.InputField}`}>
                            <span className={styles.bookTitle}>
                                Количество гостей
                            </span>
                            <div className={`${styles.input} ${styles.popupInput}`}>
                                <button onClick={() => {
                                    setGuests((Number(guests) - 1).toString())
                                }} className={styles.minus}>-</button>
                                <input type='number' value={guests} onChange={(e) => setGuests(e.target.value)}></input>
                                <button onClick={() => {
                                    setGuests((Number(guests) + 1).toString())
                                }} className={styles.plus}>+</button>
                            </div>
                        </div>
                        <div className={`${styles.Name} ${styles.InputField} ${errorName ? styles.error : ""}`}>
                            <span className={styles.bookTitle}>
                                Имя
                            </span>
                            <input onFocus={() => setErrorName(false)} type="text" id="first-name" name="first-name" autoComplete="given-name" value={name} onChange={(e) => setName(e.target.value)} className={`${styles.input} ${styles.textInput}`} placeholder='Введите имя'></input>
                        </div>
                        <div className={`${styles.Name} ${styles.InputField} ${errorNumber ? styles.error : ""}`}>
                            <span className={styles.bookTitle}>
                                Номер телефона
                            </span>
                            <PhoneInput
                                placeholder="Введите номер телефона"
                                value={number}
                                onFocus={() => setErrorNumber(false)}
                                limitMaxLength
                                //@ts-ignore
                                onChange={setNumber} />
                        </div>
                        {/* <div className={`${styles.Comment} ${styles.InputField} ${errorName ? styles.error : ""}`}>
                            <span className={styles.bookTitle}>
                                Комментарии и пожелания
                            </span>
                            <textarea ref={commentAreaRef} value={comment} onChange={(e) => setComment(e.target.value)} className={`${styles.input} ${styles.textInput}`} placeholder='Спросите что-нибудь' />
                        </div> */}
                        <div className={`${styles.SelectField}`}>
                            <div className={`${pickedServices.length > 0 ? styles.On : ""} ${styles.InputField} ${styles.AddingField} ${styles.ServiceField}`}>
                                <span className={`${styles.bookTitle}`}>
                                    <span>
                                        Дополнительные услуги
                                    </span>
                                    <button className={styles.Add} onClick={() => {
                                        setIsOpenPickServices(true)
                                    }} />
                                </span>
                                {
                                    pickedServices.length > 0 &&
                                    <div className={styles.ServicesList}>
                                        {pickedServices.map((service) =>
                                            <div className={styles.ServiceItem}>
                                                <div className={styles.imageWrapper}>
                                                    <img src={service.image} />
                                                </div>
                                                <span className={styles.Bold}>{service.title}</span>
                                                <button onClick={() => {
                                                    removeService(service)
                                                }} className={styles.Delete} />
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div onClick={() => {
                        // @ts-ignore
                        send_telegram(number, name, dateRange[0], dateRange[1])
                    }} className={`${styles.bookButton} ${loading ? styles.loading : ''} ${loadError ? styles.loadError : ''}`}
                        style={{
                            pointerEvents: loading ? "none" : "all",
                            // backgroundColor: apart.color
                        }}>
                        <div className={styles.loadingWrapper}>
                            <Loading />
                        </div>
                        <span className={`${styles.text} ${styles.Regular}`} style={{
                            display: 'flex',
                            justifyContent: "center",
                            gap: 5
                        }}>
                            {
                                apart ?
                                    <>
                                        <span className={`${styles.Price} ${styles.Bold}`}>Забронировать от <span className={styles.Numbers}>{apart.price} ₽ </span>
                                            {apart.discount && <span className={`${styles.discount}`}>{`${Math.floor(Number(apart.price.replace(",", "")) * 1.15)} ₽`}</span>}
                                        </span>
                                    </>
                                    :
                                    <>
                                        Оставить заявку
                                    </>

                            }
                        </span>
                        <span className={styles.error}>
                            Ошибка. Проверьте соединение
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Book