import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBTLsU8dg4j6qt_gshtNddmgL-5pRjSHWY",
    authDomain: "in-premium.firebaseapp.com",
    projectId: "in-premium",
    storageBucket: "in-premium.appspot.com",
    messagingSenderId: "543328797630",
    appId: "1:543328797630:web:2aa0fa547dba97e1332fef",
    measurementId: "G-L3PYZE38ET"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()